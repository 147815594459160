import axios from 'axios';
import { t } from 'i18next';
import { getBackendURL } from './utils/urls';
import { transferValueGetKey } from './utils/helpers';

const getCSRFToken = () => {
  const cookieValue = document.cookie.split('; ').find((row) => row.startsWith('csrftoken='));

  if (cookieValue) {
    return cookieValue.split('=')[1];
  } else {
    console.error('CSRF token not found in cookies.');
    return null;
  }
};

const axiosInstance = axios.create({
  baseURL: `${getBackendURL()}/api/`,
  timeout: 60000,
  withCredentials: true,
  headers: {
    accept: 'application/json',
    'X-CSRFToken': getCSRFToken(),
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  transformRequest: [
    (data, headers) => {
      if (data instanceof FormData) {
        delete headers['Content-Type'];
      } else {
        headers['Content-Type'] = 'application/json';
        if (typeof data !== 'string') return JSON.stringify(data);
      }
      return data;
    },
  ],
});

// Add a request interceptor to automatically include X-CSRFToken header
axiosInstance.interceptors.request.use(
  (config) => {
    // Add X-CSRFToken header to the request if not already present
    if (['post', 'put', 'delete'].includes(config.method.toLowerCase())) {
      config.headers['X-CSRFToken'] = getCSRFToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// this is to redirect user to the login page if user is not authenticated
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return new Promise((resolve, reject) => {
      if (!error.response && error.message === 'Network Error') {
        // Network error occurred (connection timeout, no response, etc.)
        // Handle the network error here
        error.response = {
          data: {
            errors: [
              {
                message: t(
                  transferValueGetKey(
                    'There was a problem connecting to the server. Please check your internet connection and try again.'
                  )
                ),
              },
            ],
          },
        };
      } else if (error.response && error.response.status === 401) {
        if (!window.location.pathname.startsWith('/login')) {
          window.location.href = '/login/';
        }
      }
      reject(error);
    });
  }
);

export default axiosInstance;
