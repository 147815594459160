import { format, parseISO } from 'date-fns';

const timeUnitMapToMillisecond = {
  millisecond: 1,
  ms: 1,
  second: 1000,
  sec: 1000,
  minute: 60000,
  min: 60000,
  hour: 3600000,
  h: 3600000,
};

const timeUnitMapToMinute = {
  min: 1,
  hr: 60,
  day: 1440,
  week: 10080,
  month: 43800,
  year: 525600,
};

/***
 * Calculates and formats from and to dates to ISO string based on interval
 * @param {Date|number|string} date
 * @param {number} interval
 * @returns {object}
 */
export const getDateFromAndTo = (date, interval) => {
  const dateTo = new Date(+date.valueOf());
  dateTo.setDate(dateTo.getDate() + interval);
  return { dateFrom: formatDateToISOString(date), dateTo: formatDateToISOString(dateTo) };
};

/***
 * Format date to ISO string (YYYY-MM-DD)
 * @param {Date|number|string} date
 * @returns {string|null}
 */
export const formatDateToISOString = (date) => {
  date = Date.parse(date);
  if (isNaN(date)) return null;
  return format(date, 'yyyy-MM-dd');
};

/***
 * Format date string to custom date format
 * @param {string} dateString
 * @param {string} dateFormat
 * @returns {string|null}
 */
export const convertToCustomDateString = (dateString, dateFormat = 'dd-MM-yyyy, HH:mm:ss') => {
  const timestamp = Date.parse(dateString);
  if (timestamp) {
    return format(timestamp, dateFormat);
  } else {
    return '-';
  }
};
export const translatingDateFormats = (langCode, date) => {
  const dateObject = new Date(date);
  // Translating date format based on region language
  const formattedDate = new Intl.DateTimeFormat(langCode, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(dateObject);
  return formattedDate;
};

export const convertToCustomDateMonthString = (dateString, langCode) => {
  return translatingDateFormats(langCode, format(parseISO(dateString), 'MMM dd, yyyy'));
};
export const translateMonthName = (langCode, monthName, year) => {
  // Convert the month name to a month index (0 for January, 1 for February, etc.)
  const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();

  // Create a Date object with the year, month index, and a default day
  const dateObject = new Date(year, monthIndex, 1);

  // Format the date to display the month and year in the specified language
  const formattedDate = new Intl.DateTimeFormat(langCode, {
    month: 'long',
    year: 'numeric',
  }).format(dateObject);

  return formattedDate;
};
/***
 * Calculates new date based on minutes to add
 * @param {Date} date
 * @param {number} minutes
 * @returns {Date|null}
 */
export const addMinutesToDate = (date, minutes = 0) => {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

/***
 * Calculates new date based on seconds to add
 * @param {Date} date
 * @param {number} seconds
 * @returns {Date|null}
 */
export const addSecondToDate = (date, seconds = 0) => {
  date = new Date(date);
  date.setSeconds(date.getSeconds() + seconds);
  return date;
};

/***
 * Calculates difference between 2 dates in minutes
 * @param {Date} date1
 * @param {Date} date2
 * @returns {number}
 */
export const calculateTimeMinuteDiff = (date1, date2) => {
  return Math.ceil((date1.getTime() - date2.getTime()) / (1000 * 60));
};

/**
 * calculate time difference in milliseconds or seconds or minutes or hours between two dates
 * @param {Date} startDate start date
 * @param {Date} endDate end date
 * @param {'millisecond' | 'ms' | 'seconds' | 'sec' | 'minute' | 'min' | 'hour' | 'h'} unit time unit of the time difference returned
 * @param {'ceil' | 'floor' | 'round'} roundType the types of rounding time difference
 */
export const calculateTimeDiff = (startDate, endDate, unit = 'millisecond', roundType = undefined) => {
  switch (roundType) {
    case 'ceil':
      return Math.ceil((startDate.getTime() - endDate.getTime()) / timeUnitMapToMillisecond[unit]);
    case 'floor':
      return Math.floor((startDate.getTime() - endDate.getTime()) / timeUnitMapToMillisecond[unit]);
    case 'round':
      return Math.round((startDate.getTime() - endDate.getTime()) / timeUnitMapToMillisecond[unit]);
    default:
      return (startDate.getTime() - endDate.getTime()) / timeUnitMapToMillisecond[unit];
  }
};

export const formatTimeDiff = (startDate = new Date(), endDate) => {
  const timeDiffInMin = calculateTimeDiff(startDate, endDate, 'min');

  switch (true) {
    case timeDiffInMin < 1: {
      return '<1m';
    }
    case timeDiffInMin < timeUnitMapToMinute['hr']: {
      return `${Math.floor(timeDiffInMin)}m`;
    }
    case timeDiffInMin >= timeUnitMapToMinute['hr'] && timeDiffInMin < timeUnitMapToMinute['day']: {
      return `${Math.floor(timeDiffInMin / timeUnitMapToMinute['hr'])}h`;
    }
    case timeDiffInMin >= timeUnitMapToMinute['day'] && timeDiffInMin < timeUnitMapToMinute['week']: {
      return `${Math.floor(timeDiffInMin / timeUnitMapToMinute['day'])}d`;
    }
    case timeDiffInMin >= timeUnitMapToMinute['week'] && timeDiffInMin < timeUnitMapToMinute['year']: {
      return `${Math.floor(timeDiffInMin / timeUnitMapToMinute['week'])}w`;
    }
    case timeDiffInMin >= timeUnitMapToMinute['year']: {
      return `${Math.floor(timeDiffInMin / timeUnitMapToMinute['year'])}y`;
    }
    default:
      return '';
  }
};
///////// Calculate the plan expiration date /////////
export const calculateExpirationDate = (calcNumofMonths, type, planExpiredDate) => {
  const currentDate = new Date();
  let expirationDate;
  if (calcNumofMonths === 6) {
    expirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + calcNumofMonths));
    expirationDate.setDate(expirationDate.getDate() - 1);
  } else {
    expirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + calcNumofMonths));
  }

  return expirationDate.toISOString();
};

export const addonOneMoreDayonExpiryDate = (planExpiredDate, numOfDays) => {
  // Get the current date
  const currentDate = new Date(planExpiredDate);
  // Adding one more day on expiration date
  const expirationDate = new Date(currentDate.setDate(currentDate.getDate() + numOfDays));
  return expirationDate.toISOString();
};
