import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import theme from '../../../styles/theme';

const PREFIX = 'IconLabel';

const classes = {
  icon: `${PREFIX}-icon`,
  purchase_icon: `${PREFIX}-purchase_icon`,
  fail_icon: `${PREFIX}-fail_icon`,
  alert_icon: `${PREFIX}-alert_icon`,
};

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '10px',
  padding: theme.spacing(1.25),
  color: theme.palette.common.white,
  width: 'fit-content',
  display: 'flex',

  [`&.${classes.alert_icon}`]: {
    backgroundColor: theme.palette.error.lightRed,
  },

  [`& .${classes.icon}`]: {
    width: '24px',
    height: '24px',
  },
  [`& .${classes.purchase_icon}`]: {
    width: '50px',
    height: '50px',
  },
  [`& .${classes.fail_icon}`]: {
    width: '50px',
    height: '50px',
    color: theme.palette.error.lightRed,
  },
}));

const IconLabel = (props) => {
  const { icon, className, style } = props;

  const styledIcon = React.cloneElement(icon, { className: className || classes.icon });
  return <Root sx={style}>{styledIcon}</Root>;
};

IconLabel.propTypes = {
  icon: PropTypes.element.isRequired,
};

export default IconLabel;
