import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  TextField,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ArrowDropDown } from '@mui/icons-material';
import PropTypes from 'prop-types';

import InfoToolTip from '../../../../components/layout/atoms/InfoToolTip';
import SmallButton from '../../../../components/layout/atoms/SmallButton';
import IconHeading from '../../../../components/layout/molecules/IconHeading';
import NationalIDField from '../../../management/PatientSignUp/components/NationalIDField';
import EmailOrPhoneField from '../../../management/PatientSignUp/components/EmailOrPhoneField';

import { EditIcon, FileTextIcon, UserIcon, XIcon } from '../../../../resources/icons';

import { EMAIL, PHONE_NUMBER } from '../../../../constants/validations';
import {
  ETHNIC_CODE_ENUMS,
  ETHNIC_CODE_ENUMS_STR,
  GENDER_STR_ENUMS,
  PATIENT,
  GERMANY,
} from '../../../../constants/constants';

import {
  getMyDetails,
  getPatientDetails,
  getPatientExams,
  getPatientRiskServices,
  updatePatientDetails,
} from '../../../../actions/patients';
import {
  getDeployRegion,
  getRegionSpecificEthnicity,
  getRegionSpecificSignUpFormFieldConfigs,
} from '../../../../utils/configurationHelpers';
import { transferValueGetKey } from '../../../../utils/helpers';
import { datePickerLangText } from '../../../../i18n';

const PREFIX = 'ProfileDetails';

const classes = {
  form: `${PREFIX}-form`,
  formFields: `${PREFIX}-formFields`,
  submit: `${PREFIX}-submit`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.form}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.formFields}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.submit}`]: {
    width: '30%',
  },
}));

const ProfileDetails = (props) => {
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  const { t, i18n } = useTranslation();
  const getInitialState = () => {
    let initialState = {
      accountNumber:
        (props.user?.role === PATIENT ? props.user?.account_number : props?.patientDetails.user?.account_number) || '',
      email: props.patientDetails.user?.email || '',
      first_name: props.patientDetails.user?.user_contact.first_name || '',
      last_name: props.patientDetails.user?.user_contact.last_name || '',
      postcode: props.patientDetails.user?.user_contact.address.postcode || '',
      phone_number: props.patientDetails?.user?.phone_number || '',
      gender: props.patientDetails?.demographics?.gender || '',
      ethnicity: props.patientDetails?.demographics?.ethnicity || '',
      date_of_birth: props.patientDetails?.demographics?.date_of_birth || '',
      national_id: props.patientDetails?.demographics?.national_id || '',
      metadata: props.patientDetails?.metadata,
    };

    // placeholder (-) for patient info panel
    if (props.disallowEdit) {
      for (const key in initialState) {
        if ((initialState[key] === null || initialState[key] === '') && key !== 'date_of_birth')
          initialState[key] = '-';

        if (key === 'metadata') {
          for (const prop in initialState[key]) {
            if (initialState[key][prop] === null || initialState[key][prop] === '') {
              initialState[key][prop] = '-';
            }
          }
        }
      }
    }
    return initialState;
  };
  const SignUpFormFieldConfigs = getRegionSpecificSignUpFormFieldConfigs();

  const initialState = getInitialState();

  const [isDetailDisabled, setIsDetailDisabled] = React.useState(true);
  const [showButtonLoading, setShowButtonLoading] = React.useState(false);

  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({
    email: false,
    phone_number: false,
    national_id: false,
    metadata: false,
  });

  const ethnicityElements = getRegionSpecificEthnicity();

  React.useEffect(() => {
    setValues(initialState);
  }, [props.patientDetails]);

  const handleEdit = () => {
    setIsDetailDisabled(false);
  };

  const handleSetError = (fieldName, error) => {
    setErrors({ ...errors, [fieldName]: error });
  };

  const handleReset = () => {
    setValues(initialState);
    setIsDetailDisabled(true);
  };

  const handleProfileChange = (field, event) => {
    let newValue = event.target.value;
    setValues({ ...values, [field]: newValue });
  };

  const handleMetadataChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      metadata: {
        ...values.metadata,
        [name]: value,
      },
    });
  };

  const formatDate = (date) => {
    if (isNaN(date.getDate())) return null;
    let month = date.getMonth() + 1 + '';
    let day = date.getDate() + '';
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    let formatted_date = date.getFullYear() + '-' + month + '-' + day;
    return formatted_date;
  };

  const handleDateChange = (date, target) => {
    setValues({ ...values, [target]: date });
  };

  const filterInputsByFields = (inputs, fields) => {
    let res = {};
    for (const key in inputs) {
      if (fields.includes(key) && inputs[key] !== null && inputs[key] !== initialState[key]) {
        res[key] = inputs[key];
      }
    }
    return res;
  };

  const filterMetadata = (currentMetadata, updatedMetadata) => {
    const res = {} 
    for (const [key, value] of Object.entries(currentMetadata)) {
      if (updatedMetadata?.[key] && updatedMetadata[key] !== value) {
        res[key] = updatedMetadata[key];
      }
    }

    return { metadata: res };
  }

  const renderConditionalToolTip = (innerField, title, content) => {
    if (!isDetailDisabled) {
      return (
        <Root>
          <InfoToolTip placement="bottom-start" title={title} content={content}>
            {innerField}
          </InfoToolTip>
        </Root>
      );
    } else {
      return innerField;
    }
  };

  const validateFields = (fields = []) => {
    return !fields.find((field) => errors[field]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateFields(Object.keys(errors))) return;
    setShowButtonLoading(true);
    let patientDetails = { ...values };
    //Only format the date if date is being changed
    if (patientDetails.date_of_birth !== initialState?.date_of_birth) {
      patientDetails.date_of_birth = formatDate(patientDetails?.date_of_birth);
    }

    try {
      await props.updatePatientDetails(
        props.patientDetails.user.user_contact.id,
        props.patientDetails.id,
        filterInputsByFields(patientDetails, ['first_name', 'last_name', 'postcode']),
        filterInputsByFields(patientDetails, ['gender', 'ethnicity', 'date_of_birth', 'national_id']),
        filterInputsByFields(patientDetails, ['email', 'phone_number']),
        filterMetadata(initialState?.metadata, patientDetails?.metadata),
        props.selfUpdated
      );
      setShowButtonLoading(false);
      setIsDetailDisabled(true);
    } catch (e) {
      setShowButtonLoading(false);
    }

    //Retrieve the newly updated fields
    if (props.user.role === PATIENT) {
      props.getMyDetails(props.user.role);
    } else {
      props.getPatientDetails(initialState.accountNumber).then((result) => {
        props.getPatientExams(result.id);
      });
    }
  };

  return (
    <Box>
      <form autoComplete="off">
        <div className={classes.form}>
          <Grid container alignItems="center" justifyContent="space-between" mb={2}>
            <Grid item>
              <IconHeading title={props.title} icon={<UserIcon />} />
            </Grid>
            <Grid>
              {props.disallowEdit ? null : !isDetailDisabled ? (
                <Grid container item display="flex" gap={1}>
                  <Grid item>
                    <SmallButton startIcon={<FileTextIcon />} onClick={handleSubmit} showLoading={showButtonLoading}>
                      {staticWords.Save}
                    </SmallButton>
                  </Grid>
                  <Grid item>
                    <SmallButton startIcon={<XIcon />} onClick={handleReset} showLoading={showButtonLoading}>
                      {staticWords.Cancel}
                    </SmallButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <SmallButton startIcon={<EditIcon />} onClick={handleEdit} showLoading={showButtonLoading}>
                    {staticWords.Edit_Data}
                  </SmallButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={props.disallowEdit ? 2 : 3} justifyContent="center">
            <Grid item xs={getDeployRegion() === GERMANY && !props.hidePatientDetails ? 3 : 6}>
              {renderConditionalToolTip(
                <TextField
                  type="accountNumber"
                  label={staticWords.Account_Number}
                  id="patient-account-number"
                  name="accountNumber"
                  value={values.accountNumber}
                  disabled
                  fullWidth
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                />,
                staticWords.Not_available,
                staticWords.Patient_s_account_number_is_unique_and_not_available_for_modification_
              )}
            </Grid>
            {getDeployRegion() === GERMANY && !props.hidePatientDetails && (
              <Grid item xs={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="select-insurace-type-label">{staticWords.Type}</InputLabel>
                  <Select
                    defaultValue=""
                    labelId="select-insurance-type-label"
                    name="insurance_type"
                    id="insurance-type"
                    value={values.metadata?.insurance_type}
                    disabled
                    disableUnderline={props.disallowEdit}
                    IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
                    renderValue={(value) => {
                      if (value === '-') {
                        return '-';
                      }

                      return staticWords?.[value] || '-';
                    }}
                    onChange={(e) => handleMetadataChange(e)}
                  >
                    <MenuItem value="-" disabled style={{ display: 'None' }} />
                    <MenuItem key="public" value="public">
                      {staticWords.Public}
                    </MenuItem>
                    <MenuItem key="private" value="private">
                      {staticWords.Private}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <NationalIDField
                  label={staticWords[transferValueGetKey(SignUpFormFieldConfigs.nationalID.label)]}
                  name="national_id"
                  country={props.patientDetails?.user?.country}
                  initialValue={values.national_id}
                  onError={(error) => handleSetError('national_id', error)}
                  onChange={(event) => handleProfileChange('national_id', event)}
                  pattern={SignUpFormFieldConfigs.nationalID.pattern}
                  disabled={isDetailDisabled || props.selfUpdated || !SignUpFormFieldConfigs.nationalID.allowEdit}
                  InputProps={{ disableUnderline: !!props.disallowEdit }}
                  fullWidth
                  // required={SignUpFormFieldConfigs.nationalID.required}
                  helperText={SignUpFormFieldConfigs.nationalID.helperText}
                  maxLength={SignUpFormFieldConfigs.nationalID.maxLength}
                  excludedChars={SignUpFormFieldConfigs.nationalID.excludedChars}
                />
              </Grid>
            )}
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <TextField
                  label={staticWords.First_Name}
                  name="first_name"
                  id="patient-first-name"
                  value={values.first_name}
                  onChange={(event) => handleProfileChange('first_name', event)}
                  disabled={isDetailDisabled}
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                  fullWidth
                />
              </Grid>
            )}
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <TextField
                  label={staticWords.Last_Name}
                  name="last_name"
                  id="patient-last-name"
                  value={values.last_name}
                  onChange={(event) => handleProfileChange('last_name', event)}
                  disabled={isDetailDisabled}
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                  fullWidth
                />
              </Grid>
            )}
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <EmailOrPhoneField
                  type={EMAIL}
                  name="email"
                  initialValue={values.email}
                  onChange={(e) => handleProfileChange('email', e)}
                  onError={(error) => handleSetError('email', error)}
                  showValidationError
                  fullWidth
                  autoComplete="off"
                  disabled={isDetailDisabled || props.selfUpdated}
                  InputProps={{ disableUnderline: !!props.disallowEdit }}
                />
              </Grid>
            )}
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <EmailOrPhoneField
                  defaultCountry={props.user.country}
                  type={PHONE_NUMBER}
                  name="phone_number"
                  initialValue={values.phone_number}
                  onChange={(e) => handleProfileChange('phone_number', e)}
                  onError={(error) => handleSetError('phone_number', error)}
                  showValidationError
                  fullWidth
                  autoComplete="off"
                  disabled={isDetailDisabled || props.selfUpdated}
                  InputProps={{ disableUnderline: !!props.disallowEdit }}
                />
              </Grid>
            )}
            {/* date_of_birth */}
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={datePickerLangText(i18n.language)}>
                <DesktopDatePicker
                  renderInput={(textFieldProps) => {
                    textFieldProps.inputProps.value = props.disallowEdit
                      ? textFieldProps.inputProps.value || '-'
                      : textFieldProps.inputProps.value;
                    return <TextField {...textFieldProps} sx={{ width: '100%' }} />;
                  }}
                  OpenPickerButtonProps={{
                    style: { display: props.disallowEdit && 'none' },
                  }}
                  label={staticWords.Date_of_Birth}
                  name="date_of_birth"
                  id="patient-date-of-birth"
                  value={values.date_of_birth}
                  disableFuture
                  onChange={(date) => handleDateChange(date, 'date_of_birth')}
                  disabled={isDetailDisabled}
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputFormat="dd-MM-yyyy"
                  autoOk={true}
                  fullWidth
                />
              </LocalizationProvider>
            </Grid>
            {!props.hidePatientDetails && (
              <Grid item xs={6}>
                <TextField
                  type="text"
                  label={staticWords.Post_Code}
                  name="postcode"
                  id="patient-postcode"
                  value={values.postcode}
                  onChange={(event) => handleProfileChange('postcode', event)}
                  disabled={isDetailDisabled}
                  InputProps={{ disableUnderline: props.disallowEdit && true }}
                  inputProps={{
                    maxLength: SignUpFormFieldConfigs.postcode.maxLength,
                  }}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="select-gender-label">{staticWords.Gender}</InputLabel>
                <Select
                  defaultValue=""
                  labelId="select-gender-label"
                  name="gender"
                  id="patient-gender"
                  value={values.gender}
                  onChange={(event) => handleProfileChange('gender', event)}
                  disabled={isDetailDisabled || props.disallowEdit}
                  disableUnderline={props.disallowEdit}
                  IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
                  renderValue={(value) => {
                    if (value === '-') {
                      return '-';
                    }
                    return staticWords[transferValueGetKey(GENDER_STR_ENUMS[value])];
                  }}
                >
                  <MenuItem value={null} disabled style={{ display: 'None' }} />
                  <MenuItem value="-" disabled style={{ display: 'None' }} />
                  {Object.entries(GENDER_STR_ENUMS).map(([key, genderDescription]) => (
                    <MenuItem key={key} value={key}>
                      {staticWords[transferValueGetKey(genderDescription)]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* ethnicity */}
            <Grid item xs={6}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="select-ethnicity-label">{staticWords.Ethnicity}</InputLabel>
                <Select
                  defaultValue="-"
                  labelId="select-ethnicity-label"
                  name="ethnicity"
                  id="patient-ethnicity"
                  value={values.ethnicity}
                  onChange={(event) => handleProfileChange('ethnicity', event)}
                  disabled={isDetailDisabled || props.disallowEdit}
                  disableUnderline={props.disallowEdit}
                  IconComponent={() => (props.disallowEdit ? null : <ArrowDropDown />)}
                  renderValue={(value) => {
                    return staticWords[transferValueGetKey(ethnicityElements[ETHNIC_CODE_ENUMS_STR[value]])] || '-';
                  }}
                >
                  {Object.keys(ethnicityElements || {}).map((ethnicityCodeName) => {
                    return (
                      <MenuItem value={ETHNIC_CODE_ENUMS[ethnicityCodeName]} key={ethnicityCodeName}>
                        {staticWords[transferValueGetKey(ethnicityElements[ethnicityCodeName])]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </form>
    </Box>
  );
};

ProfileDetails.propTypes = {
  updatePatientDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  patientDetails: state.patients.patientDetails,
});

export default connect(mapStateToProps, {
  updatePatientDetails,
  getPatientDetails,
  getPatientExams,
  getPatientRiskServices,
  getMyDetails,
})(ProfileDetails);
