import {
  GET_SUBSCRIPTION_PLANS,
  UPDATE_URL_RETRIEVED,
  GET_BILLING_DETAILS,
  GET_ALL_PRICES,
  LOADER,
  SET_PAYMENT_STATUS,
} from '../actions/types.js';
import { BASIC_PERMISSIONS, PREMIUM_PERMISSIONS, STANDARD_PERMISSIONS } from '../constants/subscriptions';
import { getRegionSpecificSubscriptionInclusions } from '../utils/configurationHelpers.js';
import { allotObjectToTier } from '../utils/helpers.js';

const initialState = {
  pastSubscriptions: null,
  subscriptionPlans: [],
  allPrices: null,
  billing: null,
  updateUrl: null,
  loading: false,
  packageInclusions: getRegionSpecificSubscriptionInclusions(),
  purchasedPlan: null,
};

const determinePermissions = (subscriptionPlanName, subscriptionStatus) => {
  if (subscriptionStatus == 'active' || subscriptionStatus == 'trialing') {
    return allotObjectToTier(subscriptionPlanName, BASIC_PERMISSIONS, STANDARD_PERMISSIONS, PREMIUM_PERMISSIONS);
  }
  return [];
};

export default function subscription(state = initialState, action) {
  switch (action.type) {
    case LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_SUBSCRIPTION_PLANS:
      return {
        ...state,
        subscriptionPlans: action.payload,
      };
    case GET_ALL_PRICES:
      return {
        ...state,
        allPrices: action.payload,
      };
    case GET_BILLING_DETAILS:
      return {
        ...state,
        billing: action.payload,
      };
    case SET_PAYMENT_STATUS:
      return {
        ...state,
        purchasedPlan: action.payload,
      };

    case UPDATE_URL_RETRIEVED:
      return {
        ...state,
        updateUrl: action.payload,
      };

    default:
      return state;
  }
}
