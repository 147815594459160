import { t } from 'i18next';
import { trackPromise } from 'react-promise-tracker';

import _ from 'lodash';

import axiosInstance from '../axios';
import { parseError, transferValueGetKey } from '../utils/helpers';
import logger from '../utils/debugHelpers';

import { PATIENT, TELEEYE_MD_WEB } from '../constants/constants';
import {
  GET_PATIENTS,
  GET_PATIENT_DETAILS,
  CREATE_PATIENT,
  GET_PATIENT_EXAMS,
  UPDATE_PATIENT_CONTACT_DETAILS,
  UPDATE_PATIENT_DEMOGRAPHIC_DETAILS,
  SET_SNACKBAR_SUCCESS,
  SET_SNACKBAR_ERROR,
  GET_PATIENT_RISK_SERVICES,
  SET_SNACKBAR_INFO,
} from './types';

// GET ALL PATIENTS IN ORGANISATION
export const getPatients =
  (pageSize = 7, page = 1) =>
  (dispatch) => {
    return trackPromise(
      axiosInstance
        .get(`/users/patients/list/?page=${page}&page_size=${pageSize}`)
        .then((result) => {
          const { pages_count: pagesCount, page } = result.data;
          dispatch({
            type: GET_PATIENTS,
            payload: result.data,
          });
          return { pagesCount, page };
        })
        .catch((error) => {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload:
              error.response?.data?.errors?.[0]?.message ??
              t(transferValueGetKey('There was a problem retrieving patients. Please try again.')),
          });
          return Promise.reject();
        }),
      'patients-list-area'
    );
  };

export const getPatientsListByQuery =
  (first_name = '', last_name = '', date = 0, month = 0, year = 0) =>
  (dispatch) => {
    trackPromise(
      axiosInstance
        .get(
          `/users/patients/list/${first_name !== '' || last_name !== '' || date !== 0 ? '?' : ''}${
            first_name !== '' ? `first_name=${first_name}${last_name !== '' || date !== 0 ? '&' : ''}` : ''
          }${last_name !== '' ? `last_name=${last_name}${date !== 0 ? '&' : ''}` : ''}${
            date !== 0 ? `date=${date}&month=${month}&year=${year}` : ''
          }
    `
        )
        .then((result) => {
          dispatch({
            type: GET_PATIENTS,
            payload: result.data,
          });
          if (result.data?.count === 0) {
            dispatch({
              type: SET_SNACKBAR_INFO,
              payload: t(transferValueGetKey('No patients found.')),
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: SET_SNACKBAR_ERROR,
            payload:
              error.response?.data?.errors?.[0]?.message ??
              t(transferValueGetKey('There was a problem retrieving patients. Please try again.')),
          });
        }),
      'patients-list-area'
    );
  };

// RETRIEVE PATIENT DETAILS WITH ACCOUNT NUMBER
export const getPatientDetails = (account_number) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/users/patients/detail/account/${account_number}/`)
      .then((result) => {
        dispatch({
          type: GET_PATIENT_DETAILS,
          payload: { ...result.data, account_number },
        });
        resolve(result.data);
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(
              transferValueGetKey(
                'There was a problem retrieving patient details. Please refresh the page to try again.'
              )
            ),
        });

        // reject(error);
      });
  });
};

//UPDATE PATIENT DETAILS BASED ON ACC NUMBER
export const updatePatientDetails =
  (contact_id, patient_id, contactDetails, demographicDetails, accountDetails, metadata, selfUpdated) => async (dispatch) => {
    try {
      let promises = [];
      if (!_.isEmpty(contactDetails)) {
        promises.push(axiosInstance.post(`/users/contacts/update/${contact_id}/`, contactDetails));
      }
      if (!_.isEmpty(demographicDetails)) {
        promises.push(axiosInstance.post(`/users/patients/demographics/update/${patient_id}/`, demographicDetails));
      }
      if (!_.isEmpty(accountDetails)) {
        const { email, phone_number } = accountDetails;
        email !== undefined &&
          promises.push(axiosInstance.put(`/users/patients/${patient_id}/update/email/`, { email }));
        phone_number !== undefined &&
          promises.push(axiosInstance.put(`/users/patients/${patient_id}/update/phone_number/`, { phone_number }));
      }
      if (!_.isEmpty(metadata)) {
        promises.push(axiosInstance.post(`/users/patients/demographics/update/${patient_id}/`, metadata));
      }
      await Promise.all(promises);
      const message = selfUpdated
        ? t(transferValueGetKey('Successfully updated your details.'))
        : t(transferValueGetKey('Successfully updated patient details.'));
      dispatch({
        type: SET_SNACKBAR_SUCCESS,
        payload: message,
      });
      return Promise.resolve();
    } catch (error) {
      logger.warn(error);
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload: parseError(
          error.response?.data,
          t(transferValueGetKey('There is a problem when updating patient details. Please try again later.'))
        ),
      });
      return Promise.reject(error);
    }
  };

// GET PATIENT DETAILS WITH ID
export const getPatientDetailsWithId = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/users/patients/detail/${id}/`)
      .then((result) => {
        dispatch({
          type: GET_PATIENT_DETAILS,
          payload: { ...result.data, id },
        });

        resolve(result.data);
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(
              transferValueGetKey(
                'There was a problem retrieving patient details. Please refresh the page to try again.'
              )
            ),
        });

        // reject(error);
      });
  });
};

// GET ALL EXAMS OF PATIENT - TBD
export const getPatientExams = (patient_id) => (dispatch) => {
  return trackPromise(
    axiosInstance
      .get(`/eye_screening/list/${patient_id}/`)
      .then((result) => {
        dispatch({
          type: GET_PATIENT_EXAMS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            t(
              transferValueGetKey(
                "There was a problem when retrieving patient's exams. Please refresh the page to try again."
              )
            ),
        });
      }),
    'patient-exams-area'
  );
};

// GET ALL RISK SERVICES OF PATIENT
export const getPatientRiskServices = (patient_id) => (dispatch) => {
  axiosInstance
    .get(`/services/risk_prediction_service/list/${patient_id}/`)
    .then((result) => {
      dispatch({
        type: GET_PATIENT_RISK_SERVICES,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SNACKBAR_ERROR,
        payload:
          error.response?.data?.errors?.[0]?.message ??
          t(
            transferValueGetKey(
              "There was a problem when retrieving patient's exams. Please refresh the page to try again."
            )
          ),
      });
    });
};

// export const getPatientMedicalHistory = (patient_id) => dispatch => {
//     axiosInstance
//         .get(`/medical_records/medical_history/detail/${patient_id}/`)
//         .then(result => {
//             dispatch({
//                 type: GET_PATIENT_MED_HISTORY,
//                 payload: result.data
//             })
//         }).catch(error => {
//             dispatch({
//                 type: SET_SNACKBAR_ERROR,
//                 payload: "There was a problem when retrieving patient's medical history. Please refresh the page to try again.",
//             });
//         })
// }

// export const updatePatientMedicalHistory = (patient_id, medical_history) => dispatch => {
//     axiosInstance
//         .post(`/medical_records/medical_history/update/${patient_id}/`, medical_history)
//         .then(result => {
//             dispatch({
//                 type: UPDATE_PATIENT_MED_HISTORY,
//                 payload: medical_history
//             })
//             dispatch({
//                 type: SET_SNACKBAR_SUCCESS,
//                 payload: "Successfully updated!",
//             });
//         }).catch(error => {
//             dispatch({
//                 type: SET_SNACKBAR_ERROR,
//                 payload: "There was a problem when updating patient's medical history. Please try again.",
//             });
//         })
// }

// GET MY DETAILS
export const getMyDetails = (role) => (dispatch) => {
  let api = '';
  switch (role) {
    case PATIENT:
      api = '/users/patients/detail/';
      break;
    default:
      break;
  }

  trackPromise(
    axiosInstance.get(api).then((result) => {
      dispatch({
        type: GET_PATIENT_DETAILS,
        payload: result.data,
      });
    })
  ).catch((error) => {
    dispatch({
      type: SET_SNACKBAR_ERROR,
      payload:
        error.response?.data?.errors?.[0]?.message ??
        t(transferValueGetKey('There was a problem getting your details. Please try again later.')),
    });
  });
};

// CREATE PATIENT
export const createPatient = (patient, history) => async (dispatch) => {
  try {
    const result = await axiosInstance.post('/users/accounts/create/', {
      ...patient,
      signup_application: TELEEYE_MD_WEB,
    });

    dispatch({
      type: CREATE_PATIENT,
      payload: result.data,
    });
    history.push(`/patient/${result.data.account_number}/`);
    dispatch({
      type: SET_SNACKBAR_SUCCESS,
      payload: t(transferValueGetKey('Successfully created!')),
    });
  } catch (error) {
    dispatch({
      type: SET_SNACKBAR_ERROR,
      payload:
        error.response?.data?.errors?.[0]?.message ??
        t(transferValueGetKey('There was a problem creating a new patient. Please try again later.')),
    });
  }
};
