import React from 'react';

import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import LargeButton from '../atoms/LargeButton';
import IconHeading from '../molecules/IconHeading';
import theme from '../../../styles/theme';

const PREFIX = 'AlertDialog';

const AlertDialog = (props) => {
  const { 
    title,
    alternateTitle,
    icon,
    description,
    description2,
    confirmLabel,
    cancelLabel,
    confirmButtonColor,
    buttonType,
    className,
    className_2,
    style,
    style_2,
    isOpen,
    maxWidth,
    alignText,
    isFullScreen,
    disableBackdropClick,
    disableEscapeKeyDown,
    onCancel,
    onConfirm,
   } = props;

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }

    if (typeof onCancel === 'function') {
      onCancel();
    }
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isFullScreen}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: maxWidth, // Set your width here
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <IconHeading
          title={title}
          alternateTitle={alternateTitle}
          icon={icon}
          className={className}
          className_2={className_2}
          style_2={style_2}
        />
      </DialogTitle>
      <DialogContent sx={{ minWidth: '420px' }}>
        <DialogContentText id="alert-dialog-description" sx={{ maxWidth: maxWidth ? 'auto' : '420px' }}>
          {description}
        </DialogContentText>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ maxWidth: maxWidth ? 'auto' : '420px', textAlign: alignText || 'center' }}
        >
          {description2}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ minWidth: '400px', padding: '24px' }}>
        <Grid container justifyContent="center" spacing={2}>
          {cancelLabel && (
            <Grid item xs={12} sm={confirmLabel ? 6 : 12}>
              <LargeButton 
                buttonType="secondary" 
                fullWidth 
                noMargin 
                color="primary" 
                autoFocus
                onClick={onCancel} 
              >
                {cancelLabel}
              </LargeButton>
            </Grid>
          )}
          {confirmLabel && (
            <Grid item xs={12} sm={cancelLabel ? 6 : 12}>
              <LargeButton
                fullWidth
                noMargin
                buttonType={buttonType}
                color={confirmButtonColor || theme.palette.background.blue}
                style={style}
                onClick={onConfirm}
              >
                {confirmLabel}
              </LargeButton>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  disableBackdropClick: PropTypes.bool,
  isFullScreen: PropTypes.string,
  icon: PropTypes.object,
  confirmButtonColor: PropTypes.string,
};

export default AlertDialog;
