import { createTheme } from '@mui/material';
import { fontWeight } from 'styled-system';

const theme = createTheme({
  palette: {
    /* --------------------------- main colors -------------------------------- */
    primary: { main: '#3956CC', light: '#3877F3', dark: '#4139B8', disabled: '#D8D9E0' },
    secondary: { main: '#6797F6', light: '#96B7F9', dark: '#6797F6', contrastText: '#43425D' }, // to be edited
    translucent: { normal: '#E0E3E9B3', darker: '#D5DBE7', font: '#9E9DB4' },

    /* --------------------- four dashboard colors ---------------------------- */
    error: { main: '#FF8373', light: '#FFDCD8', dark: '#C32B1B', lighter: '#BA1200' }, // red
    warning: { main: '#FFC114', light: '#FFF0C5', dark: '#FF6F00' }, // yellow
    info: { main: '#55D8FE', light: '#CEF4FF', dark: '#1063AB' }, // blue
    success: { main: '#86C548', light: '#DAF2C2', dark: '#356B00' }, // green
    urgent: { main: '#FEF0F2' }, // urgent cases background color
    grey: { main: '#E9EBF0' }, // language selector hover color

    /* -------------------------- other colors -------------------------------- */
    button: { light: '#D8E3FD', hover: 'rgba(158, 158, 158, 0.31)' },
    background: {
      default: '#F6F8FF',
      grey: '#F9F9FB',
      dark: '#ECF1FE',
      white: '#FFFFFF',
      error: '#FFDCD8',
      lightDark: '#E4E4E4',
      light: '#FFF0C5',
      blue: '#3559C8',
      lightGrey: 'rgb(255, 255, 255, 0.6)',
    },
    text: {
      primary: '#4D4F5C',
      danger: '#C32B1B',
      darkPrimary: '#4D4F5B',
      secondary: '#9E9DB4',
      disabled: '#706F8E',
      hint: '#A3A6B4',
      lightBlack: '#8A89A9',
      success: '#457000',
      lightDark: '#8B8B8B',
      orange: '#FF7600',
      blue: '#3977F4',
      white: '#FFFFFF',
      black: '#505261',
      grey: '#A09EB5',
      lightBlue: '#3B57CC',
    },
    divider: 'rgba(216,202,225,0.5)',
    border: { default: '#EBEBEB', light: '#FAF8FB', lightDark: '#D5D4DE', blue: '#3977F4', orange: 'orange' },
    outline: { default: 'rgba(216,202,225,0.5)', dark: 'rgba(224, 227, 233, 0.7)' },
  },
  typography: {
    fontFamily: '"Cabin", "Helvetica", "Arial", "sans-serif"',
    fontFamilyTitle: '"Poppins", "Helvetica", "Arial", "sans-serif"',
    h1: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '30px',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '24px',
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '16px',
      fontWeight: '700',
    },
    h5: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      fontSize: '14px',
      fontWeight: '600',
    },
    //Dashboard Subtitle
    h6: {
      fontFamily: '"Cabin", "Helvetica", "Arial", "sans-serif"',
      fontSize: '25px',
      fontWeight: '600',
      color: '#888',
    },
    subtitle1: { color: '#A09EB5' },
    subtitle3: {
      fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"',
      color: '#7D7575',
      fontWeight: '600',
    },
    button: { fontFamily: '"Poppins", "Helvetica", "Arial", "sans-serif"' },
    caption: { color: '#A09EB5' },
    caption2: { color: '#A09EB5', fontSize: '14px' },
    caption3: { color: '#7D7575' },
    links: {
      backgroundColor: 'red',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  shape: { borderRadius: 15, borderRadiusSmall: '5px', borderRadiusMedium: '10px', borderRadiusLarge: '15px' },
  outline: {
    border: '1px solid rgba(216,202,225,0.5)',
    thicker: '3px solid rgba(216,202,225,0.5)',
    card: '1px solid rgba(224, 227, 233, 0.7)',
  },
  MuiToggleButton: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        backgroundColor: 'red',
      },
    },
  },
  //override Mui v5 components
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        FormHelperTextProps: {
          sx: { color: '#A3A6B4' },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
});

theme.shadows[0] = '0 2px 6px rgba(0,0,0,0.04)';
theme.shadows[1] = '0px 0 15px rgba(0,0,0,0.1)';
theme.shadows[6] = '0 10px 13px rgba(59,21,110,0.13)';
theme.shadows[7] = '0 10px 13px rgba(59,21,110,0.13)';
theme.shadows[8] = '0px 0px 5px #e0e0e0'; //exam accordion
theme.shadows[9] = '0 20px 13px rgba(201, 201, 201, 1)'; //image card
theme.shadows[10] = '0px 0px 20px 0px rgb(0,0,1,0.1)'; //modal grid

export default theme;
